import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { animated, useSpring } from "@react-spring/web";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import {
  DgaEdit,
  Edit,
  Dot,
  RightRoundArray,
  IRODot,
} from "../../constants/icons/Icons";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { unstable_useTreeItem2 as useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { MotifCheckbox } from "@ey-xd/motif-react";
import "./TreeView.scss";

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.mode === "light" ? "#2E2E38" : theme.palette.grey[400],
  position: "relative",
  [`& .MuiTreeItem2-root .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
  zIndex: 1,
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: "row-reverse",
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: "#FFFFFF",
  height: "4em",
  zIndex: 1,
  [`&.Mui-expanded `]: {
    "&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon":
      {
        color:
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
      },
  },
  "&:hover": {
    backgroundColor: "#E6E6E9",
    color:
      theme.palette.mode === "light" ? theme.palette.primary.main : "white",
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor: "#E6E6E9",
    color: theme.palette.primary.contrastText,
  },
}));
const AnimatedCollapse = animated(Collapse);

const TransitionComponent = (props) => {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
};

const StyledTreeItemLabelText = styled(Typography)({
  color: "#2E2E38",
  fontFamily: "EYInterstate",
  fontWeight: 500,
  fontSize: "1rem",
});

const CustomLabel = ({
  icon: Icon,
  expandable,
  expanded,
  children,
  description,
  onEditClick,
  onViewClick,
  pageName,
  itemId,
  checkboxState,
  dotColorState,
  handleCheckboxChange,
  ...other
}) => {
  const handleEditClick = (event) => {
    onEditClick();
  };

  const handleViewClick = (event) => {
    onViewClick();
  };

  const dotColor = dotColorState ? dotColorState[itemId]?.dotColor : null;
  const maxScore = dotColorState ? dotColorState[itemId]?.maxScore : "N/A";

  const showButtons =
    (typeof itemId === "string" &&
      itemId?.includes("name") &&
      pageName === "IRO") ||
    pageName === "SM";

  const showViewButton =
    typeof itemId === "string" &&
    itemId?.includes("name") &&
    pageName === "IRO";

  const showCheckbox = pageName === "IROModal";

  const showDgaButtons = pageName === "DgaContext";

  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {expandable && (
          <Box
            sx={{
              mr: 1,
              fontSize: "1.5rem",
              color: expandable ? "#AFAEBA" : "#FFFFFF",
            }}
          >
            {expanded ? "-" : "+"}
          </Box>
        )}
        {showCheckbox ? (
          <MotifCheckbox
            id={`checkbox-${itemId}`}
            name={`checkbox-${itemId}`}
            value=""
            className="iro-checkbox"
            checked={checkboxState[itemId] || false}
            onChange={() => handleCheckboxChange(itemId)}
            sx={{ mr: 1, fontSize: "1.2rem" }}
          />
        ) : (
          Icon && (
            <Box
              component={Icon}
              className="labelIcon"
              color="inherit"
              sx={{ mr: 1, fontSize: "1.2rem" }}
            />
          )
        )}
        <Box>
          <StyledTreeItemLabelText variant="body2" sx={{ paddingLeft: "10px" }}>
            {dotColor ? <IRODot fill={dotColor} /> : <Dot />} &nbsp;
            {maxScore && pageName === "IRO" && (
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ paddingLeft: "10px", fontSize: "1rem", color: "inherit" }}
              >
                {maxScore}
              </Typography>
            )}{" "}
            &nbsp;
            {children}
          </StyledTreeItemLabelText>
          {pageName === "SM" && (
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ paddingLeft: "10px" }}
            >
              Edit to view detailed description of {children}
            </Typography>
          )}
        </Box>
      </Box>
      {(showButtons || showViewButton || showDgaButtons) && (
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          onClick={(event) => event.stopPropagation()}
        >
          {showButtons && (
            <Box onClick={handleEditClick} style={{ cursor: "pointer" }}>
              <Edit /> &nbsp;
            </Box>
          )}
          {showViewButton && (
            <Box style={{ cursor: "pointer" }} onClick={handleViewClick}>
              <RightRoundArray /> &nbsp;
            </Box>
          )}
          {showDgaButtons && (
            <Box onClick={handleEditClick} style={{ cursor: "pointer" }}>
              <DgaEdit /> &nbsp;
            </Box>
          )}
        </Box>
      )}
    </TreeItem2Label>
  );
};

CustomLabel.propTypes = {
  icon: PropTypes.elementType,
  expandable: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  description: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  checkboxState: PropTypes.object,
  dotColorState: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
};

const isExpandable = (reactChildren) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  {
    id,
    itemId,
    label,
    description,
    disabled,
    onEditClick,
    onViewClick,
    pageName,
    checkboxState,
    dotColorState,
    handleCheckboxChange,
    onSelect,
    children,
    ...other
  },
  ref
) {
  const {
    getRootProps,
    getContentProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({
    id,
    itemId,
    children,
    label,
    description,
    disabled,
    rootRef: ref,
  });

  const expandable = isExpandable(children);

  const handleSelect = (event) => {
    event.stopPropagation();
    if (onSelect) {
      onSelect(itemId);
    }
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)} onClick={handleSelect}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx("content", {
              "Mui-expanded": status.expanded,
              "Mui-selected": status.selected,
              "Mui-focused": status.focused,
              "Mui-disabled": status.disabled,
            }),
          })}
        >
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps({
              expandable,
              expanded: status.expanded,
            })}
            description={description}
            onEditClick={(event) => {
              onEditClick(itemId);
            }}
            onViewClick={(event) => {
              onViewClick(itemId);
            }}
            pageName={pageName}
            itemId={itemId}
            checkboxState={checkboxState}
            dotColorState={dotColorState}
            handleCheckboxChange={handleCheckboxChange}
          >
            {label}
          </CustomLabel>
        </CustomTreeItemContent>
        {children && (
          <TransitionComponent {...getGroupTransitionProps()}>
            {children}
          </TransitionComponent>
        )}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

CustomTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  checkboxState: PropTypes.object,
  dotColorState: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  onSelect: PropTypes.func,
  children: PropTypes.node,
};

const TreeView = ({
  items,
  defaultExpandedItems = [],
  defaultSelectedItems = "",
  sx = {},
  onEditClick,
  onViewClick,
  pageName,
  checkboxState,
  dotColorState,
  handleCheckboxChange,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(defaultExpandedItems);

  useEffect(() => {
    setExpanded(defaultExpandedItems);
  }, [defaultExpandedItems]);

  const renderTreeItem = (props) => {
    return (
      <CustomTreeItem
        {...props}
        onEditClick={onEditClick}
        onViewClick={onViewClick}
        pageName={pageName}
        className="tree-item"
        checkboxState={checkboxState}
        dotColorState={dotColorState}
        handleCheckboxChange={handleCheckboxChange}
        onSelect={onSelect}
      />
    );
  };

  return (
    <RichTreeView
      items={items}
      defaultExpandedItems={expanded}
      defaultSelectedItems={defaultSelectedItems}
      sx={{
        maxWidth: "100%",
        ...sx,
      }}
      slots={{
        item: renderTreeItem,
      }}
    />
  );
};

TreeView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      itemId: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      children: PropTypes.array,
    })
  ).isRequired,
  defaultExpandedItems: PropTypes.arrayOf(PropTypes.string),
  defaultSelectedItems: PropTypes.string,
  sx: PropTypes.object,
  onEditClick: PropTypes.func,
  onViewClick: PropTypes.func,
  pageName: PropTypes.string,
  checkboxState: PropTypes.object,
  dotColorState: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default TreeView;
