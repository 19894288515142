import React from "react";
import PropTypes from "prop-types";
import "./DMACards.scss";
import { MotifButton } from "@ey-xd/motif-react";
import { Download } from "../../../../constants/icons/Icons";

const DMACards = ({
  headerIconLeft,
  headerIconRight,
  rightHeaderType,
  lefttype,
  Longlist,
  Shortlist,
  Dashboard,
  references,
  UTC,
  IROIdentificationAndAssessment,
  ReportingImplication,
  onTabChange,
  onLonglistClick, // Receive the download function here
}) => {
  const handleReferenceClick = (reference) => {
    const label = reference.label;
    onTabChange(label);
  };

  return (
    <div className="dma-overview-card">
      <div className="dma-understanding-div">
        <label className="dma-overview-header">
          {headerIconLeft}&nbsp;
          {lefttype === UTC && UTC}
          {lefttype === IROIdentificationAndAssessment &&
            IROIdentificationAndAssessment}
          {lefttype === UTC && (
            <MotifButton
              className="context-selection-button-download"
              onClick={onLonglistClick}
              size="small"
              variant="primary-alt"
            >
              Download long list
              <span className="icon">
                <Download />
              </span>
            </MotifButton>
          )}
        </label>

        <div className="dma-overview-content-div">
          {references?.map((reference) => (
            <div
              key={reference.id}
              onClick={() => handleReferenceClick(reference)}
              className="dma-overview-row"
            >
              <span className="dma-overview-number-of-reference">
                {reference.count}
              </span>
              <label className="dma-overview-content">{reference.label}</label>
            </div>
          ))}
        </div>
      </div>
      {rightHeaderType && (
        <div className="dma-stakeholder-div">
          <label className="dma-overview-header">
            {headerIconRight}&nbsp;
            {rightHeaderType === ReportingImplication && ReportingImplication}
          </label>
          {rightHeaderType === ReportingImplication && (
            <div className="dma-overview-content-div">
              <div
                className="dma-overview-row-reporting"
                onClick={onLonglistClick}
              >
                <label
                  className="dma-reporting-list"
                  style={{ cursor: "pointer" }}
                >
                  {Longlist}
                </label>
              </div>
              <div className="dma-overview-row-reporting">
                <label className="dma-reporting-list">{Shortlist}</label>
              </div>
              <div className="dma-overview-row-reporting">
                <label className="dma-reporting-list">{Dashboard}</label>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DMACards.propTypes = {
  headerIconLeft: PropTypes.node,
  headerIconRight: PropTypes.node,
  Longlist: PropTypes.string,
  Shortlist: PropTypes.string,
  Dashboard: PropTypes.string,
  references: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  rightHeaderType: PropTypes.string,
  lefttype: PropTypes.string,
  UTC: PropTypes.string,
  IROIdentificationAndAssessment: PropTypes.string,
  ReportingImplication: PropTypes.string,
  onTabChange: PropTypes.func,
  onLonglistClick: PropTypes.func, // Add the prop type for the download function
};

export default DMACards;