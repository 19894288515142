import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import {
  Stake,
  Beat,
  Iro,
  IroLite,
  ReportingLite,
  Reporting,
  BeatLite,
  StakeLite,
} from "../../constants/icons/Icons";
import "./DMA.scss";
import DMAOverview from "./dmaOverview/DMAOverview";
import DmaContext from "./dmaContext/DmaContext";
import DmaProcessSpecification from "./dmaProcessSpecification/DmaProcessSpecification";
import DMAReporting from "./dmaReporting/DMAReporting";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import DMAIroAssessment from "./dmaIROAssessment/DMAIroAssessment";
import { getAllIro } from "../../features/slices/Iro";
import { MotifProgressLoader } from "@ey-xd/motif-react";

const DMA = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("sources");
  const [assessmentIroId, setAssessmentIroId] = useState(null);
  const [iroData, setIroData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const switchToUnderstandTheContext = (tab) => {
    setActiveTab(tab);
    handleStepChange(1);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );

  useEffect(() => {
    if (activeIndex === 3 && (!iroData.length || !assessmentIroId)) {
      setLoading(true);
      dispatch(getAllIro({ moduleId, token }))
        .unwrap()
        .then((response) => {
          if (response.success && response.data.length > 0) {
            setIroData(response.data);
            setAssessmentIroId(response.data[0].id);
          }
        })
        .catch((error) => console.error("Failed to fetch IRO data:", error))
        .finally(() => setLoading(false));
    }
  }, [activeIndex, iroData, assessmentIroId, dispatch, moduleId, token]);

  const stepItems = [
    { label: t("All projects"), link: "/", key: "all-projects", token },
    {
      label: projectName,
      link: `/project-home/${projectId}`,
      key: `project-home-${projectId}`,
      token,
    },
    {
      label: project?.name,
      link: `/project-home/${projectId}`,
      key: `project-name-${projectId}`,
      token,
    },
    { label: t("DMA"), link: "#", key: "dma" },
  ];

  const navigateToAssessment = () => {
    handleStepChange(3);
  };

  const navigateToIdentification = () => {
    handleStepChange(1);
    setActiveTab("IRO identification");
  };

  return (
    <div className="dma-page">
      <Header />
      <Stepper
        activeIndex={activeIndex}
        onChange={handleStepChange}
        items={stepItems}
      >
        <div icon={getIcon(0, <Beat />, <BeatLite />)} text={t("Overview")}>
          <DMAOverview onTabChange={switchToUnderstandTheContext} />
        </div>
        <div
          icon={getIcon(1, <Stake />, <StakeLite />)}
          text={t("UnderstandTheContext")}
        >
          <DmaContext
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setAssessmentIroId={setAssessmentIroId}
            setIroData={setIroData}
            navigateToAssessment={navigateToAssessment}
          />
        </div>
        <div
          icon={getIcon(2, <Beat />, <BeatLite />)}
          text={t("ProcessSpecification")}
        >
          <DmaProcessSpecification />
        </div>
        <div icon={getIcon(3, <Iro />, <IroLite />)} text={t("IROAssessment")}>
          {loading ? (
            <MotifProgressLoader show variant="default" />
          ) : (
            <DMAIroAssessment
              navigateToIdentification={navigateToIdentification}
              setAssessmentIroId={setAssessmentIroId}
              assessmentIroId={assessmentIroId}
              getIroData={iroData}
            />
          )}
        </div>
        <div
          icon={getIcon(4, <Reporting />, <ReportingLite />)}
          text={t("ReportingImplication")}
        >
          <DMAReporting />
        </div>
      </Stepper>
    </div>
  );
};

export default DMA;