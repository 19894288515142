import React from "react";
import PropTypes from "prop-types";
import { Grid, Card, Box, Typography, Button, IconButton } from "@mui/material";
import { Edit, DgaAR } from "../../../constants/icons/Icons";

const AssessmentCard = ({ item, onEdit, onARClick }) => (
  <Grid item xs={12} key={item.id}>
    <Card
      variant="outlined"
      sx={{ display: "flex", alignItems: "center", padding: 2 }}
    >
      <Box flex={1}>
        <Typography
          variant="subtitle1"
          sx={{ color: " #2E2E38", marginBottom: 2 }}
        >
          <Box component="span" fontWeight="bold">
            {item.esrs}
          </Box>
          {" - "}
          <Box component="span" fontWeight="normal">
            {item.name}
          </Box>
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ffffff",
            color: "#724BC3",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#ffffff",
              color: "#724BC3",
            },
          }}
          size="small"
        >
          {item.relatedAR === null ? "N/A" : item.relatedAR}
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ffffff",
            color: "#724BC3",
            marginLeft: "10px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#ffffff",
              color: "#724BC3",
            },
          }}
          size="small"
        >
          {item.dataType === null ? "N/A" : item.dataType}
        </Button>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => onARClick(item)}>
          <DgaAR />
        </IconButton>
        <IconButton onClick={() => onEdit(item)}>
          <Edit />
        </IconButton>
      </Box>
    </Card>
  </Grid>
);

AssessmentCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    esrs: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    relatedAR: PropTypes.string,
    dataType: PropTypes.string,
    conditionalOrAlternativeDP: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onARClick: PropTypes.func.isRequired,
};

export default AssessmentCard;