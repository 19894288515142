import React from "react";
import {
  MotifLabel,
  MotifButton,
  MotifFormField,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import {
  LeftArrowForPrevious,
  NavLeft,
  RightArrowForNext,
  DgaEdit,
} from "../../constants/icons/Icons";
import "./IROAssessmentSubheader.scss";
import { Box, Typography, Card, CardContent } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";

const IROAssessmentSubheader = ({
  header,
  subHeader,
  impact,
  previousButtonLabel,
  nextButtonLabel,
  onBackClick,
  handleNextClick,
  handlePreviousClick,
  SelectDisclosure,
  EsrsArray,
  GeneralDisclosureArray,
  SelectESRS,
  pagename,
  esrs,
  generalDisclosure,
  setEsrs,
  setGeneralDisclosure,
}) => {
  return pagename === "IRO" ? (
    <div className="iro-assessment-subheader-container">
      <div className="iro-assessment-subheader-section">
        <div className="iro-assessment-subheader-section1">
          <div className="iro-left-arrow" onClick={onBackClick}>
            <NavLeft aria-label="nav-left-icon" />
          </div>

          <div className="iro-assessment-subheader-body">
            <div className="iro-assessment-name-section">
              <h2 className="motif-h6-default-regular"> {header}</h2>
              <p className="iro-impact iro-impact-label">{impact} </p>
            </div>

            <h5 className="iro-assessment-sub-name motif-body2-default-light">
              {subHeader}
            </h5>
          </div>
        </div>
        <div className="iro-assessment-subheader-section2">
          <MotifButton
            className="button cancel-button next-previous-btn"
            onClick={handlePreviousClick}
          >
            <LeftArrowForPrevious aria-label="left-arrow-icon" /> &nbsp;{" "}
            {previousButtonLabel}
          </MotifButton>
          <MotifButton
            className="button cancel-button next-previous-btn"
            onClick={handleNextClick}
          >
            {nextButtonLabel} &nbsp;{" "}
            <RightArrowForNext aria-label="right-arrow-icon" />
          </MotifButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="iro-assessment-subheader-container">
        <div className="iro-assessment-subheader-section">
          <div className="iro-assessment-subheader-section1">
            <div className="iro-assessment-subheader-body">
              <div className="iro-assessment-name-section">
                <h2 className="motif-h6-default-regular"> {header}</h2>
              </div>

              <h5 className="iro-assessment-sub-name motif-body2-default-light">
                {subHeader}
              </h5>
            </div>
          </div>
          <div className="iro-assessment-subheader-section2">
            <MotifButton
              className="dga-button"
              onClick={handlePreviousClick}
              size="small"
            >
              {previousButtonLabel} &nbsp; <DgaEdit />
            </MotifButton>
            <div className="select-box">
              <MotifFormField>
                <MotifLabel id="select-esrs-label" position="in">
                  {SelectESRS}
                </MotifLabel>
                <MotifSelect
                  className="select-dga-input"
                  ariaLabelledBy="select-esrs-label"
                  placeholder="Select ESRS"
                  onChange={(e) => setEsrs(e)}
                  value={esrs}
                  labelPosition="in"
                  visibleOptions={3}
                >
                  <MotifOption value="">All</MotifOption>
                  {EsrsArray?.map((item, index) => (
                    <MotifOption key={index} value={item}>
                      {item}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="select-box">
              <MotifFormField>
                <MotifLabel id="select-disclosure-label" position="in">
                  {SelectDisclosure}
                </MotifLabel>
                <MotifSelect
                  className="select-dga-input"
                  ariaLabelledBy="select-disclosure-label"
                  placeholder="Select Disclosure"
                  onChange={(e) => setGeneralDisclosure(e)}
                  value={generalDisclosure}
                  labelPosition="in"
                  visibleOptions={3}
                >
                  <MotifOption value="">All</MotifOption>
                  {GeneralDisclosureArray?.map((item, index) => (
                    <MotifOption key={index} value={item}>
                      {item}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
        </div>
      </div>
      <div className="iro-dga-assessment-subheader-container">
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            border: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#083153",
              p: 2,
              height: "100%",
            }}
          >
            <HelpOutlineIcon sx={{ color: "#FFFFFF" }} />
          </Box>
          <CardContent sx={{ p: 0, ml: 2 }}>
            <Typography variant="body1" color="textPrimary">
              Does the entity disclose whether the sustainability statement has
              been prepared on a consolidated or individual basis, and to what
              extent it covers the value chain, including any exemptions used in
              relation to intellectual property or ongoing negotiations?
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

IROAssessmentSubheader.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  impact: PropTypes.string,
  previousButtonLabel: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  handlePreviousClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  SelectDisclosure: PropTypes.string,
  EsrsArray: PropTypes.array,
  GeneralDisclosureArray: PropTypes.array,
  SelectESRS: PropTypes.string,
  pagename: PropTypes.string,
  esrs: PropTypes.string,
  generalDisclosure: PropTypes.string,
  setEsrs: PropTypes.func,
  setGeneralDisclosure: PropTypes.func,
};

export default IROAssessmentSubheader;
