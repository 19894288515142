import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

const WaterfallChart = ({ data, width = 800, height = 400 }) => {
  const svgRef = useRef();
  useEffect(() => {
    d3.select(svgRef.current).selectAll("*").remove();

    const margin = { top: 60, right: 20, bottom: 80, left: 80 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Calculate cumulative values and prepare waterfall data
    const waterfallData = data?.map((d, i) => ({
      ...d,
      start: i === 0 ? 0 : data[i - 1].cummulativeValue,
      end: d.cummulativeValue,
    }));

    const types = Array.from(new Set(data.map((d) => d.type)));
    const typeTotals = types.map((type) => ({
      type,
      value: d3.sum(
        data.filter((d) => d.type === type),
        (d) => d.value
      ),
    }));

    let cumulativeTotal = 0;
    const stackedData = typeTotals
      .filter((d) => d.value > 0) // Exclude zero totals
      .map((d) => {
        const start = cumulativeTotal;
        cumulativeTotal += d.value;
        return { ...d, start, end: cumulativeTotal, label: "Total" };
      });
    const extendedData = [...waterfallData, ...stackedData];

    // Check if all values are zero
    if (d3.max(extendedData, (d) => d.end) === 0) {
      d3.select(svgRef.current)
        .append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .attr("font-size", "16px")
        .attr("fill", "#666")
        .text("No data to display");
      return;
    }

    // Set up SVG canvas
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define scales
    const x = d3
      .scaleBand()
      .domain(extendedData.map((d) => d.label))
      .range([0, chartWidth])
      .padding(0.3);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(extendedData, (d) => d.end) * 1.2]) // Reduce bar heights
      .nice()
      .range([chartHeight, 0]);

    const colorScale = d3
      .scaleOrdinal()
      .domain(types)
      .range(d3.schemeCategory10);

    // Draw legends in a horizontal layout with wrapping
    const legendGroup = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(0, -${margin.top - 10})`);

    const legendItems = legendGroup
      .selectAll(".legend-item")
      .data(types)
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (d, i) => {
        const row = Math.floor(i / 4); // Max 4 items per row
        const col = i % 4;
        return `translate(${col * 120}, ${row * 20})`; // Spacing
      });

    legendItems
      .append("rect")
      .attr("x", 0)
      .attr("width", 15)
      .attr("height", 15)
      .attr("fill", (d) => colorScale(d));

    legendItems
      .append("text")
      .attr("x", 20)
      .attr("y", 12)
      .attr("font-size", "12px")
      .attr("text-anchor", "start")
      .text((d) => (d.length > 10 ? `${d.slice(0, 10)}…` : d)) // Ellipsis for long text
      .append("title") // Tooltip for full text
      .text((d) => d);

    // Draw x-axis with rotated text labels

    // Draw x-axis with truncated text labels (ellipsis and tooltip)
    svg
      .append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll(".tick text")
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .attr("dy", "1em") // Adjust the vertical position of the text
      .each(function (d) {
        const text = d3.select(this);
        const labelText = d;
        const maxChars = 4; // Maximum characters before truncation

        // Check if truncation is needed
        if (labelText.length > maxChars) {
          const truncated = labelText.slice(0, maxChars) + "…"; // Add ellipsis
          text.text(truncated); // Set truncated text
          text.append("title").text(labelText); // Add full text as a tooltip
        } else {
          text.text(labelText); // Set full text if no truncation is needed
        }
      });

    // Draw bars for non-zero values
    svg
      .selectAll(".bar")
      .data(waterfallData.filter((d) => d.value > 0)) // Filter non-zero values
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.label))
      .attr("y", (d) => y(d.end))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => colorScale(d.type));

    // Draw connectors
    svg
      .selectAll(".connector")
      .data(waterfallData.slice(0, -1))
      .enter()
      .append("line")
      .attr("class", "connector")
      .attr("x1", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("x2", (d, i) => x(waterfallData[i + 1].label) + x.bandwidth() / 2)
      .attr("y1", (d) => y(d.end))
      .attr("y2", (d, i) => y(waterfallData[i + 1].start))
      .attr("stroke", "#999")
      .attr("stroke-width", 1);

    // Draw the stacked total bar
    svg
      .selectAll(".total-bar")
      .data(stackedData)
      .enter()
      .append("rect")
      .attr("class", "total-bar")
      .attr("x", x("Total"))
      .attr("y", (d) => y(d.end))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => colorScale(d.type));

    // Add label inside the total stacked bar
    svg
      .selectAll(".total-label")
      .data(stackedData)
      .enter()
      .append("text")
      .attr("class", "total-label")
      .attr("x", x("Total") + x.bandwidth() / 2)
      .attr("y", (d) => y(d.start + d.value / 2))
      .attr("text-anchor", "middle")
      .attr("fill", "white")
      .attr("font-weight", "bold")
      .text((d) => d.value);

    // Add bar labels for each segment
    svg
      .selectAll(".bar-label")
      .data(waterfallData)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.end) - 5)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text((d) => d.value);
  }, [data, height, width]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

WaterfallChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      cummulativeValue: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WaterfallChart;
