import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ExcelJS from "exceljs";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import DMACards from "./dmaCards/DMACards";
import { Stake, Iro, Reporting } from "../../../constants/icons/Icons";
import {
  fetchUnderstandingTheContextCount,
  fetchIROCount,
} from "../../../features/slices/DMAOverview";
import { fetchExcelData } from "../../../features/slices/LongListData";
import ErrorModal from "../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { handleError as handleLogoutError } from "../../../utils/handleError";

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const DMAOverview = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("sources");
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { contextData, iroData, isError, errorMessage } = useSelector(
    (state) => state.dmaOverview
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(fetchUnderstandingTheContextCount({ moduleId, token })),
          dispatch(fetchIROCount({ moduleId, token })),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      setActiveTab("Overview");
      setIsExcelLoading(false);
      setIsLoading(true);
    };
  }, [dispatch, moduleId, token]);

  const addSheet = (workbook, sheetName, data) => {
    const groupHeadersByTypeAndName = (
      items,
      typeKey,
      nameKey,
      isGeography = false
    ) => {
      return items.reduce((acc, item) => {
        const type = isGeography ? "" : item[typeKey];
        const name = item[nameKey];
        const key = isGeography ? name : `${type} - ${name}`;
        if (!acc[type]) acc[type] = {};
        if (!acc[type][name]) acc[type][name] = key;
        return acc;
      }, {});
    };

    const sourcesGrouped = groupHeadersByTypeAndName(
      data.flatMap((item) => item.sources || []),
      "sourceType",
      "name"
    );

    const valueChainGrouped = groupHeadersByTypeAndName(
      data.flatMap((item) => item.valueChain || []),
      "valueChainType",
      "name"
    );

    const stakeholdersGrouped = groupHeadersByTypeAndName(
      data.flatMap((item) => item.stakeholders || []),
      "stakeHolderType",
      "name"
    );

    const geographiesGrouped = groupHeadersByTypeAndName(
      data.flatMap((item) => item.geographies || []),
      "name",
      "name",
      true
    );

    const actProServicesGrouped = groupHeadersByTypeAndName(
      data.flatMap((item) => item.actProServices || []),
      "actProServiceType",
      "name"
    );

    const flattenGroupedHeaders = (groupedHeaders) => {
      return Object.values(groupedHeaders).flatMap((group) =>
        Object.values(group)
      );
    };

    const sourcesHeaders = flattenGroupedHeaders(sourcesGrouped);
    const valueChainHeaders = flattenGroupedHeaders(valueChainGrouped);
    const stakeholderHeaders = flattenGroupedHeaders(stakeholdersGrouped);
    const geographyHeaders = flattenGroupedHeaders(geographiesGrouped);
    const actProServiceHeaders = flattenGroupedHeaders(actProServicesGrouped);

    // Combine all unique dynamic headers
    const dynamicHeaders = [
      ...sourcesHeaders,
      ...valueChainHeaders,
      ...stakeholderHeaders,
      ...geographyHeaders,
      ...actProServiceHeaders,
    ];

    // Define static headers
    const staticHeaders = [
      "Section",
      "ESRS",
      "Topic",
      "SubTopic",
      "SubSubTopic",
    ];
    const headers = [...staticHeaders, ...dynamicHeaders];
    const worksheetData = [headers];
    const worksheet = workbook.addWorksheet(sheetName);
    worksheet.addRows(worksheetData);
    const extraHeaderRow = worksheet.insertRow(1, []);

    extraHeaderRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    extraHeaderRow.getCell(1).font = {
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    extraHeaderRow.getCell(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF000000" },
    };

    let currentCol = staticHeaders.length + 1;

    const mergeAndStyleHeader = (startCol, endCol, headerText) => {
      worksheet.mergeCells(1, startCol, 1, endCol);
      const cell = worksheet.getCell(1, startCol);
      cell.value = headerText;
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF000000" },
      };
    };

    mergeAndStyleHeader(1, staticHeaders.length, " ");

    if (sourcesHeaders.length > 0) {
      mergeAndStyleHeader(
        currentCol,
        currentCol + sourcesHeaders.length - 1,
        "Sources"
      );
      currentCol += sourcesHeaders.length;
    }

    if (valueChainHeaders.length > 0) {
      mergeAndStyleHeader(
        currentCol,
        currentCol + valueChainHeaders.length - 1,
        "Value Chain"
      );
      currentCol += valueChainHeaders.length;
    }

    if (stakeholderHeaders.length > 0) {
      mergeAndStyleHeader(
        currentCol,
        currentCol + stakeholderHeaders.length - 1,
        "Stakeholders"
      );
      currentCol += stakeholderHeaders.length;
    }

    if (geographyHeaders.length > 0) {
      mergeAndStyleHeader(
        currentCol,
        currentCol + geographyHeaders.length - 1,
        "Geographies"
      );
      currentCol += geographyHeaders.length;
    }

    if (actProServiceHeaders.length > 0) {
      mergeAndStyleHeader(
        currentCol,
        currentCol + actProServiceHeaders.length - 1,
        "ActProServices"
      );
      currentCol += actProServiceHeaders.length;
    }

    // Apply styling to the header row
    const headerRow = worksheet.getRow(2);
    headerRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, size: 12 };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        textRotation: colNumber > staticHeaders.length ? 90 : 0,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" },
      };
      cell.border = {
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
      };
    });

    // Group data by sourceType and valueChainType
    const groupedData = data.reduce((acc, item) => {
      const groupKey = `${item.sources
        ?.map((s) => s.sourceType)
        .join(",")} | ${item.valueChain
        ?.map((vc) => vc.valueChainType)
        .join(",")}`;
      if (!acc[groupKey]) acc[groupKey] = [];
      acc[groupKey].push(item);
      return acc;
    }, {});

    // Process each group and add rows to the worksheet
    Object.keys(groupedData).forEach((key) => {
      const group = groupedData[key];
      group.forEach((item) => {
        const row = [
          item.section,
          item.esrs,
          item.topic,
          item.subTopic,
          item.subSubTopic,
        ];
        dynamicHeaders.forEach((header) => {
          if (sourcesHeaders.includes(header)) {
            const source = item.sources?.find(
              (source) => `${source.sourceType} - ${source.name}` === header
            );
            row.push(source ? (source.isActive ? "X" : "") : "");
          } else if (valueChainHeaders.includes(header)) {
            const valueChain = item.valueChain?.find(
              (chain) => `${chain.valueChainType} - ${chain.name}` === header
            );
            row.push(valueChain ? (valueChain.isActive ? "X" : "") : "");
          } else if (stakeholderHeaders.includes(header)) {
            const stakeholder = item.stakeholders?.find(
              (st) => `${st.stakeHolderType} - ${st.name}` === header
            );
            row.push(stakeholder ? (stakeholder.isActive ? "X" : "") : "");
          } else if (geographyHeaders.includes(header)) {
            const geography = item.geographies?.find(
              (geo) => geo.name === header
            );
            row.push(geography ? (geography.isActive ? "X" : "") : "");
          } else if (actProServiceHeaders.includes(header)) {
            const actProService = item.actProServices?.find(
              (aps) => `${aps.actProServiceType} - ${aps.name}` === header
            );
            row.push(actProService ? (actProService.isActive ? "X" : "") : "");
          } else {
            row.push("");
          }
        });

        worksheetData.push(row);
      });
    });

    worksheet.addRows(worksheetData.slice(1));

    worksheetData.slice(1).forEach((row, rowIndex) => {
      const dataRow = worksheet.getRow(rowIndex + 2); // Row index starts at 1, so add 2
      dataRow.eachCell((cell, colNumber) => {
        if (cell.value === "X") {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
        }
      });
    });
    worksheet.columns = headers.map((header, colNumber) => ({
      width: colNumber > staticHeaders.length ? 10 : 20,
    }));

    worksheet.views = [{ state: "frozen", ySplit: 2 }];
  };

  const formatExcelData = (data) => {
    const workbook = new ExcelJS.Workbook();
    addSheet(workbook, "Sustainability Matters", data);
    return workbook;
  };

  const downloadExcel = async (data) => {
    const workbook = formatExcelData(data);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sustainability_matters.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    //window.URL.revokeObjectURL(url); // Clean up the URL object
  };

  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse?.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleLogoutError(dispatch, navigate)}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div className="dma-overview-container">
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader className="loader" show variant="default" />
      ) : (
        <>
          <DMACards
            headerIconLeft={<Stake />}
            lefttype={t("UTC")}
            UTC={t("UTC")}
            references={contextData?.data}
            headerIconRight={<Reporting />}
            Longlist={t("Longlist")}
            Shortlist={t("Shortlist")}
            Dashboard={t("Dashboard")}
            ReportingImplication={t("ReportingImplication")}
            rightHeaderType={t("ReportingImplication")}
            onTabChange={onTabChange}
            onLonglistClick={handleDownloadExcel}
          />
          <DMACards
            headerIconLeft={<Iro />}
            headerIconRight={<Stake />}
            references={iroData?.data}
            lefttype={t("IROIdentificationAndAssessment")}
            IROIdentificationAndAssessment={t("IROIdentificationAndAssessment")}
            onTabChange={setActiveTab}
          />
        </>
      )}
    </div>
  );
};

export default DMAOverview;
